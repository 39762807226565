import { FilterBy, SortBy, SearchBy, Pagination, TableTools } from 'lib/types/tableTools'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { Beacon, BeaconType, TableState } from 'lib/types'
import { useGlobal } from './GlobalProvider'
import { ApiResponseV5, getRequestV5 } from 'models/api/rest'
import { ALL_ORGS_SELECTED, BeaconsColumnKeys } from 'lib/constants'
import * as utils from '../components/partial-pages/BeaconsPage/utils'
// Define the Beacon type (modify this based on your actual data structure)

// Define the context type
type BeaconsContextType = {
  beacons: Beacon[]
  loading: boolean
  // tableTools: TableTools<BeaconType | null, BeaconsColumnKeys>
  tableState: TableState<string, BeaconsColumnKeys>
  pagination: Pagination

  // Define your functions and their types here
  setBeacons: React.Dispatch<React.SetStateAction<Beacon[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  // setTableTools: React.Dispatch<React.SetStateAction<TableTools<BeaconType | null, BeaconsColumnKeys>>>
  setTableState: React.Dispatch<React.SetStateAction<TableState<string, BeaconsColumnKeys>>>
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

// Initialize
const BeaconsContext = createContext<BeaconsContextType>({
  beacons: [],
  loading: false,
  // tableTools: { pagination: { before: null, after: null } },
  tableState: {
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  },
  pagination: {
    currentPage: null,
  },
  setBeacons: () => {},
  setLoading: () => {},
  setTableState: () => {},
  setPagination: () => {},
  // setTableTools: () => {},
})

type BeaconsProviderProps = {
  children: ReactNode
}

export const useBeacons = () => {
  const context = useContext(BeaconsContext)
  if (!context) {
    throw new Error('useBeacons must be used within a BeaconsProvider')
  }
  return context
}

export const BeaconsProvider: React.FC<BeaconsProviderProps> = ({ children }) => {
  const [beacons, setBeacons] = useState<Beacon[]>([])
  const [loading, setLoading] = useState(false)
  const [tableState, setTableState] = useState<TableState<string, BeaconsColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })
  const [pagination, setPagination] = useState<Pagination>({ currentPage: null })

  const { selectedOrgId, refreshFlag, setRefreshFlag } = useGlobal()

  // Implement functions for managing beacon data, search, sort, filter, pagination, etc.
  // For example, you can define functions like addBeacon, removeBeacon, setSearch, setSort, etc.

  const load = () => {
    setLoading(true)
    getRequestV5({
      endpoint: '/beacons',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0]
            ? { field: 'beaconType', value: tableState.filterBy.toUpperCase() }
            : undefined,
        sortBy: { field: tableState.sortBy.field, order: tableState.sortBy.order },
      },
    }).then(response => {
      const data = response.data
      setBeacons(data.data)
      setPagination({ ...data.meta })
      setLoading(false)
      console.log('Beacons context loaded')
    })
  }
  // useEffect(() => {
  //   load()
  // }, [])

  useEffect(() => {
    if (refreshFlag) {
      load()
      setRefreshFlag(false)
    }
  }, [refreshFlag])

  // useEffect(() => {
  //   console.log({ pagination })
  //   getRequestV5({ endpoint: '/beacons', headers: {}, params: { pagination: pagination } })
  // }, [pagination])

  // Update the beacons whenever one of these params change. Pagination will be handled separately.
  useEffect(() => {
    load()
  }, [selectedOrgId])

  return (
    <BeaconsContext.Provider
      value={{
        beacons,
        setBeacons,
        loading,
        setLoading,
        pagination,
        setPagination,
        tableState,
        setTableState,
        // tableTools,
        // setTableTools,
        /* functions */
      }}
    >
      {children}
    </BeaconsContext.Provider>
  )
}
