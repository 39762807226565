import { FilterBy, SortBy, SearchBy, Pagination, TableTools } from 'lib/types/tableTools'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { ResponderV5, ResponderType, TableState } from 'lib/types'
import { useGlobal } from './GlobalProvider'
import { ApiResponseV5, getRequestV5 } from 'models/api/rest'
import { ALL_ORGS_SELECTED, RespondersColumnKeys } from 'lib/constants'
import * as utils from '../components/partial-pages/RespondersPage/utils'
// Define the responder type (modify this based on your actual data structure)

// Define the context type
type RespondersContextType = {
  responders: ResponderV5[]
  loading: boolean
  // tableTools: TableTools<responderType | null, respondersColumnKeys>
  tableState: TableState<string, RespondersColumnKeys>
  pagination: Pagination

  // Define your functions and their types here
  setResponders: React.Dispatch<React.SetStateAction<ResponderV5[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setTableState: React.Dispatch<React.SetStateAction<TableState<string, RespondersColumnKeys>>>
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

// Initialize
const RespondersContext = createContext<RespondersContextType>({
  responders: [],
  loading: false,
  // tableTools: { pagination: { before: null, after: null } },
  tableState: {
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  },
  pagination: {
    currentPage: null,
  },
  setResponders: () => {},
  setLoading: () => {},
  setTableState: () => {},
  setPagination: () => {},
  // setTableTools: () => {},
})

type RespondersProviderProps = {
  children: ReactNode
}

export const useResponders = () => {
  const context = useContext(RespondersContext)
  if (!context) {
    throw new Error('responders must be used within a respondersProvider')
  }
  return context
}

export const RespondersProvider: React.FC<RespondersProviderProps> = ({ children }) => {
  const [responders, setResponders] = useState<ResponderV5[]>([])
  const [loading, setLoading] = useState(false)
  const [tableState, setTableState] = useState<TableState<string, RespondersColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })
  const [pagination, setPagination] = useState<Pagination>({ currentPage: null })

  const { selectedOrgId, refreshFlag, setRefreshFlag } = useGlobal()

  // useEffect(() => {
  //   setLoading(true)
  //   getRequestV5({
  //     endpoint: '/responders',
  //     headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
  //     params: {
  //       filterBy:
  //         tableState.filterBy != utils.getFilterOptions()[0]
  //           ? { field: 'responderType', value: tableState.filterBy }
  //           : undefined,
  //       sortBy: { field: tableState.sortBy.field, order: tableState.sortBy.order },
  //     },
  //   }).then(response => {
  //     const data = response.data
  //     setResponders(data.data)
  //     setPagination({ ...data.meta })
  //     setLoading(false)
  //   })
  // }, [])

  const load = () => {
    setLoading(true)
    getRequestV5({
      endpoint: '/responders',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // not all responders
            ? { field: 'responderType', value: tableState.filterBy == 'Users' ? 'user' : 'alert_source' }
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: { field: 'health', order: tableState.sortBy.order },
      },
    }).then(response => {
      const data: ApiResponseV5 = response.data
      setResponders(data.data)
      setPagination(data.meta)
      setLoading(false)
    })
  }

  useEffect(() => {
    load()
  }, [selectedOrgId])

  useEffect(() => {
    if (refreshFlag) {
      load()
      setRefreshFlag(false)
    }
  }, [refreshFlag])

  return (
    <RespondersContext.Provider
      value={{
        responders,
        setResponders,
        loading,
        setLoading,
        pagination,
        setPagination,
        tableState,
        setTableState,
      }}
    >
      {children}
    </RespondersContext.Provider>
  )
}
