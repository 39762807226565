import { FilterBy, SortBy, SearchBy, Pagination, TableTools } from 'lib/types/tableTools'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { Device, TableState } from 'lib/types'
import { useGlobal } from './GlobalProvider'
import { ApiResponseV5, getRequestV5 } from 'models/api/rest'
import { DevicesColumnKeys, ALL_ORGS_SELECTED } from 'lib/constants'
import * as utils from '../components/partial-pages/ButtonsDevicesPage/utils'

// Define the context type
type DevicesContextType = {
  devices: Device[]
  loading: boolean
  // tableTools: TableTools<BeaconType | null, BeaconsColumnKeys>
  tableState: TableState<string, DevicesColumnKeys>
  pagination: Pagination

  // Define your functions and their types here
  setDevices: React.Dispatch<React.SetStateAction<Device[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  // setTableTools: React.Dispatch<React.SetStateAction<TableTools<BeaconType | null, BeaconsColumnKeys>>>
  setTableState: React.Dispatch<React.SetStateAction<TableState<string, DevicesColumnKeys>>>
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

// Initialize
const DevicesContext = createContext<DevicesContextType>({
  devices: [],
  loading: false,
  // tableTools: { pagination: { before: null, after: null } },
  tableState: {
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  },
  pagination: {
    currentPage: null,
  },
  setDevices: () => {},
  setLoading: () => {},
  setTableState: () => {},
  setPagination: () => {},
  // setTableTools: () => {},
})

type DevicesProviderProps = {
  children: ReactNode
}

export const useDevices = () => {
  const context = useContext(DevicesContext)
  if (!context) {
    throw new Error('useBeacons must be used within a BeaconsProvider')
  }
  return context
}

export const DevicesProvider: React.FC<DevicesProviderProps> = ({ children }) => {
  const [devices, setDevices] = useState<Device[]>([])
  const [loading, setLoading] = useState(false)
  const [tableState, setTableState] = useState<TableState<string, DevicesColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })
  const [pagination, setPagination] = useState<Pagination>({ currentPage: null })

  const { selectedOrgId, refreshFlag, setRefreshFlag } = useGlobal()

  const load = () => {
    setLoading(true)
    getRequestV5({
      endpoint: '/alert_sources',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
            ? { field: 'deviceType', value: tableState.filterBy }
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: { field: tableState.sortBy.field, order: tableState.sortBy.order },
      },
    }).then(response => {
      const data: ApiResponseV5 = response.data
      setDevices(data.data)
      setPagination(data.meta)
      setLoading(false)
    })
  }

  useEffect(() => {
    console.log('Devices context loaded')
  }, [])

  useEffect(() => {
    load()
  }, [selectedOrgId])

  useEffect(() => {
    if (refreshFlag) {
      load()
      setRefreshFlag(false)
    }
  }, [refreshFlag])

  // useEffect(() => {
  //   setLoading(true)
  //   getRequestV5({
  //     endpoint: '/alert_sources',
  //     headers: selectedOrgId ? { org_id: selectedOrgId } : {},
  //     params: {
  //       filterBy:
  //         tableState.filterBy != utils.getFilterOptions()[0] // not all Devices
  //           ? { field: 'beaconType', value: tableState.filterBy.toUpperCase() } //********* fix */
  //           : undefined,
  //       searchBy: { value: tableState.searchBy },
  //     },
  //   }).then(response => {
  //     const data: ApiResponseV5 = response.data
  //     setDevices(data.data)
  //     console.log('devices: ', data.data)
  //     setPagination(data.meta)
  //     setLoading(false)
  //     // setTableTools({ ...tableTools, pagination: data.meta })
  //   })
  // }, [selectedOrgId])

  return (
    <DevicesContext.Provider
      value={{
        devices,
        setDevices,
        loading,
        setLoading,
        pagination,
        setPagination,
        tableState,
        setTableState,
      }}
    >
      {children}
    </DevicesContext.Provider>
  )
}
